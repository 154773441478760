import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import type { RichContent } from '@wix/ricos';
import type {
  PaginationState,
  ResourcePaginationStateReady,
} from '@wix/comments-ooi-client/controller';

import { Mode } from '@wix/ambassador-reactions-v1-identity-reaction/types';
import type { IGroupMember } from 'api/members';

import * as thunks from './thunks';
import { feedItemsAdapter } from './adapter';

import type { FeedStateExtras, IReactParams, IUnreactParams } from './types';

export const initialState = feedItemsAdapter.getInitialState<FeedStateExtras>({
  statuses: {
    create: {},
    fetch: {},
    fetchMore: {},
    update: {},
    remove: {},
    subscribe: {},
    react: {},
    pin: {},
  },
});

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateTotalComments(state, action: PayloadAction<PaginationState>) {
      feedItemsAdapter.updateMany(state, totalCommentsChanges(action.payload));
    },

    updateDraft(
      state,
      action: PayloadAction<{
        draftContent?: RichContent | undefined;
      }>,
    ) {
      state.draft = action.payload.draftContent;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunks.create.pending, (state) => {
        state.statuses.create.pending = true;
        state.statuses.create.error = false;
      })
      .addCase(thunks.create.rejected, (state) => {
        state.statuses.create.pending = false;
        state.statuses.create.error = true;
      })
      .addCase(thunks.create.fulfilled, (state, action) => {
        const item = action.payload;

        state.statuses.create.pending = false;
        state.statuses.create.error = false;
        state.statuses.create.itemId = item.feedItemId;

        feedItemsAdapter.addOne(state, item);
      });

    builder
      .addCase(thunks.update.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.update[feedItemId] = {
          pending: true,
          error: false,
        };
      })
      .addCase(thunks.update.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;
        state.statuses.update[feedItemId] = {
          pending: false,
          error: true,
        };
      })
      .addCase(thunks.update.fulfilled, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.update[feedItemId] = {};

        feedItemsAdapter.updateOne(state, {
          id: feedItemId,
          changes: {
            entity: action.payload.entity,
            mediaData: action.payload.mediaData,
          },
        });
      });

    builder
      .addCase(thunks.get.pending, (state) => {
        state.statuses.fetch.pending = true;
        state.statuses.fetch.error = false;
      })
      .addCase(thunks.get.rejected, (state) => {
        state.statuses.fetch.pending = false;
        state.statuses.fetch.error = true;
      })
      .addCase(thunks.get.fulfilled, (state, action) => {
        const item = action.payload;

        state.statuses.fetch.pending = false;
        state.statuses.fetch.error = false;

        feedItemsAdapter.setOne(state, item);
      });

    builder
      .addCase(thunks.fetch.pending, (state, action) => {
        const { cursor } = action.meta.arg;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: false,
            pending: true,
          };
          return;
        }

        feedItemsAdapter.removeAll(state);

        state.statuses.fetch = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        const { cursor } = action.meta.arg;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: true,
            pending: false,
          };
          return;
        }

        state.statuses.fetch = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.fetch.fulfilled, function (state, action) {
        const { data } = action.payload;
        const { cursor } = action.meta.arg;

        state.total = data.total;
        state.nextCursor = data.nextCursor;
        state.prevCursor = data.prevCursor;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: false,
            pending: false,
          };
          return feedItemsAdapter.addMany(state, data.items);
        }

        state.statuses.fetch = {
          error: false,
          pending: false,
        };

        feedItemsAdapter.setAll(state, data.items);
      });

    builder
      .addCase(thunks.fetchCentralFeed.pending, (state, action) => {
        const { cursor } = action.meta.arg;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: false,
            pending: true,
          };
          return;
        }

        state.statuses.fetch = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.fetchCentralFeed.rejected, (state, action) => {
        const { cursor } = action.meta.arg;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: true,
            pending: false,
          };
          return;
        }

        state.statuses.fetch = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.fetchCentralFeed.fulfilled, function (state, action) {
        const { data } = action.payload;
        const { cursor } = action.meta.arg;

        state.total = data.total;
        state.nextCursor = data.nextCursor;
        state.prevCursor = data.prevCursor;
        state.feedPermissions = data.feedPermissions;

        if (cursor?.cursor) {
          state.statuses.fetchMore = {
            error: false,
            pending: false,
          };
          return feedItemsAdapter.addMany(state, data.items);
        }

        state.statuses.fetch = {
          error: false,
          pending: false,
        };

        feedItemsAdapter.setAll(state, data.items);
      });

    builder
      .addCase(thunks.subscribe.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.subscribe.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.subscribe.fulfilled, function (state, action) {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {};

        feedItemsAdapter.updateOne(state, {
          id: feedItemId,
          changes: {
            requesterContext: action.payload.data,
          },
        });
      });

    builder
      .addCase(thunks.unsubscribe.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.unsubscribe.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.unsubscribe.fulfilled, function (state, action) {
        const { feedItemId } = action.meta.arg;

        state.statuses.subscribe[feedItemId] = {};

        feedItemsAdapter.updateOne(state, {
          id: feedItemId,
          changes: {
            requesterContext: action.payload.data,
          },
        });
      });

    builder
      .addCase(thunks.pin.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.pin.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.pin.fulfilled, function (state, action) {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {};

        feedItemsAdapter.updateOne(state, {
          id: feedItemId,
          changes: {
            pin: action.payload.data,
          },
        });
      });

    builder
      .addCase(thunks.unpin.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.unpin.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.unpin.fulfilled, function (state, action) {
        const { feedItemId } = action.meta.arg;

        state.statuses.pin[feedItemId] = {};

        feedItemsAdapter.updateOne(state, {
          id: feedItemId,
          changes: {
            pin: undefined,
          },
        });
      });

    builder
      .addCase(thunks.react.pending, (state, action) => {
        const { code } = action.meta.arg;

        react(state, action.meta.arg);

        state.statuses.react[code] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.react.rejected, (state, action) => {
        const { code } = action.meta.arg;

        unreact(state, action.meta.arg);

        state.statuses.react[code] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.react.fulfilled, function (state, action) {
        const { code } = action.meta.arg;

        state.statuses.react[code] = {};
      });

    builder
      .addCase(thunks.unreact.pending, (state, action) => {
        const { code } = action.meta.arg;

        unreact(state, action.meta.arg);

        state.statuses.react[code] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.unreact.rejected, (state, action) => {
        const { code } = action.meta.arg;

        react(state, action.meta.arg);

        state.statuses.react[code] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.unreact.fulfilled, function (state, action) {
        const { code } = action.meta.arg;

        state.statuses.react[code] = {};
      });

    builder
      .addCase(thunks.remove.pending, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.remove[feedItemId] = {
          error: false,
          pending: true,
        };
      })
      .addCase(thunks.remove.rejected, (state, action) => {
        const { feedItemId } = action.meta.arg;

        state.statuses.remove[feedItemId] = {
          error: true,
          pending: false,
        };
      })
      .addCase(thunks.remove.fulfilled, (state, action) => {
        const { feedItemId } = action.meta.arg;
        state.statuses.remove[feedItemId] = {};

        feedItemsAdapter.removeOne(state, feedItemId);
      });
  },
});

function totalCommentsChanges(resources: PaginationState) {
  return Object.entries(resources).map((data) => {
    const [id, state] = data as [string, ResourcePaginationStateReady];

    return {
      id,
      changes: {
        comments: {
          total: state.totals,
        },
      },
    };
  });
}

function react(state: EntityState<any>, payload: IReactParams) {
  const { code, feedItemId, user, mode } = payload;

  const isReplace = mode === Mode.REPLACE;
  const feedItem = feedItemsAdapter
    .getSelectors()
    .selectById(state, feedItemId);

  if (!feedItem?.reactions) {
    return;
  }

  const reactions = !isReplace
    ? feedItem.reactions.reactions.concat([
        { code, user: user as IGroupMember },
      ])
    : feedItem.reactions.reactions
        .filter((reaction) => reaction.user.siteMemberId !== user.siteMemberId)
        .concat([{ code, user: user as IGroupMember }]);

  feedItemsAdapter.updateOne(state, {
    id: feedItemId,
    changes: {
      reactions: {
        reactions,
        total: reactions.length,
      },
    },
  });
}

function unreact(state: EntityState<any>, payload: IUnreactParams) {
  const { code, feedItemId, user } = payload;
  const feedItem = feedItemsAdapter
    .getSelectors()
    .selectById(state, feedItemId);

  if (!feedItem?.reactions) {
    return;
  }

  const reactions = feedItem.reactions.reactions.filter((reaction) => {
    if (reaction.user.siteMemberId !== user.siteMemberId) {
      return true;
    }

    return reaction.code !== code;
  });

  feedItemsAdapter.updateOne(state, {
    id: feedItemId,
    changes: {
      reactions: {
        reactions,
        total: reactions.length,
      },
    },
  });
}
